import { Formik, FormikProps } from "formik";
import React, { FC, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import Event from "./components/Event";
import PreviousSignUps from "./components/PreviousSignUps";
import FieldCampus from "./fields/FieldCampus";
import FieldCourse from "./fields/FieldCourse";
import CollegeForm from "./forms/CollegeForm";
import SchoolForm from "./forms/SchoolForm";
import { validate } from "../../helpers/validate";
import useAddPageView from "../../hooks/useAddPageView";
import useCourses from "../../hooks/useCourses";
import useInitialValues from "../../hooks/useInitialValues";
import useOnSubmit from "../../hooks/useOnSubmit";
import useStyles from "../../hooks/useStyles";
import { SignUpData } from "../../types/signUp";
import { COLORS } from "../../constants/colors";

import SignUpNow from "./components/SignUpNow";
import Banner from "./components/Banner";
import TopMenu from "./components/TopMenu";
import YoutubeEmbed from "./components/YoutubeEmbed";
import FieldIsOnline from "./fields/FieldIsOnline";

const BasicForm: FC = () => {
  const __DEV__ = process.env.NODE_ENV === "development";
  useAddPageView(!__DEV__);

  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "college";

  const { styles, isDesktop } = useStyles();
  const { onSubmit } = useOnSubmit();
  const initialValues = useInitialValues();

  const formik = useRef<FormikProps<SignUpData>>(null);

  const { courses, courseCampus, eventDate, eventDates } = useCourses();

  useEffect(() => {
    if (formik.current && courseCampus) {
      formik.current.setFieldValue("course", courseCampus.courseID);
    }
  }, [courseCampus, formik]);

  return (
    <div>
      <TopMenu />
      <div style={styles.screen}>
        <Banner mode={mode} />
        <SignUpNow />
        {mode === "college" && (
          <div>
            <YoutubeEmbed embedId="GpeiKVaY00M" />
            {isDesktop && <br />}
          </div>
        )}
        <div style={styles.blueContainer}>
          <div style={styles.container}>
            {__DEV__ && (
              <div style={{ fontSize: 22, color: COLORS.error }}>
                <b>DEV MODE</b>
                <br />
                <br />
              </div>
            )}
            {/* {mode === "ead-eja" && (
              <div>
                <p>
                  <b>Prezado(a) candidato(a),</b>
                </p>
                <p>
                  No momento, nossa inscrição para o EJA está ativa. No entanto,
                  as aulas iniciarão somente em 2024. Sua inscrição será
                  registrada em nosso banco de dados, e assim que as aulas
                  iniciarem, entraremos em contato com você!
                </p>
              </div>
            )} */}
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate}
              validateOnBlur={true}
              innerRef={formik}
            >
              {({ handleSubmit }) => (
                <>
                  <Event eventDate={eventDate} eventDates={eventDates} />
                  <PreviousSignUps />
                  {mode !== "school" && <h3>Curso, período e unidade:</h3>}
                  <form style={styles.form} onSubmit={handleSubmit}>
                    {mode !== "school" && (
                      <div style={{ ...styles.form, ...styles.fullWidth }}>
                        <FieldCourse courses={courses} />
                        <FieldCampus />
                      </div>
                    )}
                    {mode === "college" && eventDate && (
                      <div style={{ ...styles.form, ...styles.fullWidth }}>
                        <h3 style={styles.fullWidth}>
                          Como quer fazer a prova?
                        </h3>
                        <FieldIsOnline eventDate={eventDate} />
                      </div>
                    )}
                    <h3 style={styles.fullWidth}>Dados do responsável</h3>
                    {mode === "school" && <SchoolForm courses={courses} />}
                    {mode !== "school" && <CollegeForm />}
                  </form>
                </>
              )}
            </Formik>
          </div>
          <div style={{ fontSize: 10, color: COLORS.white }}>
            <br />
            V1.3.1
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicForm;
