import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { COLORS } from "../../../constants/colors";

import useFirebaseLog from "../../../hooks/useFirebaseLog";
import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldAgree: FC = () => {
  const { firebaseLog } = useFirebaseLog();
  const { styles } = useStyles();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<SignUpData>();
  const type = () => {
    switch (values.mode) {
      case "school":
        return "do Desafio Cultural";
      case "college":
      case "unitec-college":
      case "uniten-college":
        return "do Vestibular Social";
      case "post":
        return "da Pós graduação";
      case "technical":
        return "do Curso Técnico";
      default:
        return "do EAD";
    }
  };
  const reg = (url: string) => (
    <div style={{ width: "100%" }}>
      <a href={url} target="_blank" rel="noreferrer">
        Clique aqui para abrir o regulamento
      </a>
    </div>
  );

  return (
    <div>
      {values.mode === "school" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_desafio_cultural_2025.pdf"
        )}
      {values.mode === "post" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "ead-eja" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "ead-college" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_ead_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "ead-post" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_ead_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "college" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "technical" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/cursotecnico/regulamento-cursostecnicos.pdf"
        )}
      {values.mode === "unitec-college" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "unitec-ead-college" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_ead_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "unitec-ead-post" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_ead_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "uniten-college" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_proc_seletivo2025-1.pdf"
        )}
      {values.mode === "uniten-ead-college" &&
        reg(
          "https://drummond.com.br/wp-content/uploads/regulamentos/regulamento_graduacao_ead_proc_seletivo2025-1.pdf"
        )}
      <FormGroup style={{ ...styles.input, ...styles.fullWidth }}>
        <FormControlLabel
          control={
            <Checkbox
              id="agree"
              name="agree"
              value={values.agree}
              onChange={handleChange}
              onBlur={(e) => {
                firebaseLog({
                  action: "Field: agree",
                  message: values.agree.toString(),
                  type: "log",
                });
                handleBlur(e);
              }}
            />
          }
          label={`Li e concordo com o Regulamento ${type()}.`}
        />
        <FormHelperText style={{ color: COLORS.error }}>
          {touched.agree && errors.agree}
        </FormHelperText>
      </FormGroup>
    </div>
  );
};

export default FieldAgree;
